"use client";

import { Desktop } from "@/common/MediaQueries";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { FC, useRef } from "react";
import "swiper/css";
import "swiper/css/pagination";
import Footer from "./Footer";
import Header from "./Header";

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;

  :focus {
    outline: 0;
  }
`;

const StyledContent = styled.div`
  /* flex-grow: 1; */
`;

const StyledButton = styled(Button)`
  position: fixed;
  z-index: -10;
  background-color: black;
  color: white;
  top: -100px;
  &:focus-visible {
    z-index: 9999999;
    top: 0px;
  }
`;

const Layout: FC<any> = ({ children }) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const handleClick = () => {
    console.log("handleClick");
    if (contentRef.current) {
      contentRef.current.focus();
    }
  };
  return (
    <>
      <StyledMain id="mainContent" tabIndex={-1} ref={mainRef}>
        <Desktop>
          <StyledButton id="skip-to-content" tabIndex={3} onClick={handleClick}>
            דלג לתוכן המרכזי
          </StyledButton>
        </Desktop>
        <Header />
        <StyledContent id="mainContent" tabIndex={-1} ref={contentRef}>
          {children}
        </StyledContent>
        {children}
      </StyledMain>
      <Footer />
    </>
  );
};

export default Layout;
