const colors = {
  black: "#262626",
  white: "#FFFFFF",
  brandBlue: "#1C69D4",
  grey: "#8E8E8E",
  blue: "#0072CE",
  darkBlue: "#0653B6",
  lightBlue: "#CDDAEA",
  bmwBlue: "#002653",
  errorRed: "#CC0000",
  greyBmw: "#666",
};

export default colors;
